<template>
  <div>

    <div class="row mt-5">
      <div class="col-lg-6 mb-5">
        <label>{{ $t('expenses_income_transaction.code') }}</label>
        <input type="text" v-model="data.code" class="form-control" :class="validation && validation.code ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.code[0] }}
                            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('expenses_income_transaction.date') }}</label>
        <input type="date" v-model="data.expenses_date" class="form-control" :class="validation && validation.expenses_date ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.expenses_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.expenses_date[0] }}
                            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('expenses_income_transaction.amount') }}<span class="text-danger">*</span></label>
        <input type="number" v-model="data.amount" class="form-control" :class="validation && validation.amount ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.amount[0] }}
                            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('expenses_income_transaction.currency') }}<span class="text-danger">*</span></label>
        <div class="input-group">
          <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
            <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
          </select>
          <div class="input-group-prepend">
            <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
          </div>
        </div>
        <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('exchange_rate') }}</label>
        <input type="number" v-model="data.exchange_rate" step="0.01" min="0" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.exchange_rate[0] }}
                            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('expenses_income_transaction.payment_method') }}<span class="text-danger">*</span></label>
        <select name="" id="payment_method" v-model="data.payment_method" class="custom-select" :class="validation && validation.payment_method ? 'is-invalid' : ''">
          <option v-for="row in payment_methods" :value="row.id" :key="row.id">{{ row.title }}</option>
        </select>
        <span v-if="validation && validation.payment_method" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.payment_method[0] }}
                            </span>
      </div>
      <div class="col-lg-6 mb-5" v-if="data.payment_method == 2 || data.payment_method == 3">
        <label>{{ $t('expenses_income_transaction.bank') }}<span class="text-danger">*</span></label>
        <div class="input-group">
          <multiselect v-model="bank"
                       :class="validation && validation.method_type_id ? 'is-invalid' : ''"
                       :placeholder="$t('expenses.bank')"
                       label="name"
                       track-by="id"
                       :options="banks"
                       :multiple="false"
                       :taggable="false"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false"
                       @input="useBank"
                       @search-change="getBanks($event)">
          </multiselect>
          <div class="input-group-prepend">
            <a class="btn btn-primary" href="/finances/banks/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
          </div>
        </div>
        <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.banks') }}.</span>
        <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.type_id[0] }}
                            </span>
      </div>
      <div class="col-lg-6 mb-5" v-else>
        <label>{{ $t('expenses_income_transaction.treasury') }}<span class="text-danger">*</span></label>
        <div class="input-group">
          <multiselect v-model="treasury"
                       :class="validation && validation.type_id ? 'is-invalid' : ''"
                       :placeholder="$t('expenses.treasury')"
                       label="name"
                       track-by="id"
                       :options="treasuries"
                       :multiple="false"
                       :taggable="false"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false"
                       @input="useTreasury">
          </multiselect>
          <div class="input-group-prepend">
            <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
          </div>
        </div>
        <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.treasuries') }}.</span>
        <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.type_id[0] }}
                            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('status') }}</label>
        <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
          <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
        </select>
        <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
      </div>

      <div class="col-lg-12 mb-8 text-center">
        <button type="button" class="btn btn-primary m-2" @click="save">{{ $t('save') }}</button>
      </div>
    </div>

  <div class="row mt-5">
    <div class="col-12">
      <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="tablePaymant">

        <template slot="doc_type_name" slot-scope="props">
          <p>{{props.row.doc_type_name}}</p>
          <p>{{props.row.obj_name}}</p>
          <p>{{props.row.obj_code}}</p>
        </template>

        <template slot="status" slot-scope="props">
          {{ props.row.status_name }}
        </template>

        <template slot="actions" slot-scope="props">
          <v-icon small class="text-info mr-2" @click="editItem(props.row)">mdi-pencil</v-icon>
          <v-icon small class="text-danger" @click="deleteItem(props.row)">mdi-delete</v-icon>

          <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('more_actions') " variant="outline-primary" class="m-2">
            <router-link v-if="props.row.class ==1" target="_blank" class="dropdown-item" :to="`/print/print-incomes-transactions-customer/${props.row.id}/print`">
              {{ $t('expenses_incomes_print.print_transaction') }}
            </router-link>
            <router-link v-if="props.row.class ==1" target="_blank" class="dropdown-item" :to="`/print/print-incomes-transactions-customer/${props.row.id}/pdf`">
              {{ $t('export_pdf') }}
            </router-link>


          </b-dropdown>

        </template>
      </v-server-table>
    </div>
  </div>


  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "PaymentCredit",
  props: ['customerId', 'customerName'],
  data() {
    return {
      mainRoute: 'finances/expenses_incomes',
      mainRouteDependency: 'base/dependency',

      data: {
        name: null,
        code: null,
        amount: null,
        currency_id: null,
        // tax_id: null,
        // tax_val: null,
        expenses_date: null,
        recurring_date: null,
        // frecuency: null,
        // attachment: null,
        // notes: null,
        status: 1,
        // is_recurring: false,
        // inventory_id: null,
        // branch_id: null,
        // account_id: null,
        exchange_rate: null,
        payment_method: null,
        type_id: null,
        type_type: null,
        doc_type: "Customer",
        doc_type_id: null,
        class: "1",
      },


      currencies: [],
      status_list: [],
      payment_methods: [],
      treasuries: [],
      treasury: null,
      bank: null,
      banks: [],
      validation: null,

      data_list: [],
      idEditing: null,
      isEditing: false,

      columns: ['id','name', 'code', 'class_name', 'doc_type_name','expenses_date', 'amount', 'currency_name', 'status', 'actions'],
    };
  },
  watch: {
    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
      } else {
        this.data.exchange_rate = null;
      }
    },
    customerName: function (val) {
      this.data.name = val ;//+ ' '+ (new Date().toISOString().substr(0, 10));
      this.getTimeZone().then((res)=>{
        this.data.name = this.data.name + ' '+ res;
      });
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('expenses_income_transaction.id'),
          name: that.$t('expenses_income_transaction.name'),
          code: that.$t('expenses_income_transaction.code'),
          expenses_date: that.$t('expenses_income_transaction.date'),
          amount: that.$t('expenses_income_transaction.amount'),
          currency_name: that.$t('expenses_income_transaction.currency'),
          class_name: that.$t('expenses_income_transaction.class'),
          doc_type_name: that.$t('expenses_income_transaction.doc_type'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },

        filterByColumn: false,
        customFilters: [],
        filterable: false,
        orderBy: {'column': 'id'},
        sortable: [],
        alwaysShowPerPageSelect: false,
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            // filter: data.query,
            doc_type: that.data.doc_type,
            doc_type_id: that.customerId,
            class: that.data.class,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  methods: {
    ...timeZoneStructure,
    getFetch() {
      this.$refs.tablePaymant.refresh();
    },

    useBank(obj) {
      this.data.type_id = obj.id;
      this.data.type_type = 'Bank';

    },
    useTreasury(obj) {
      this.data.type_id = obj.id;
      this.data.type_type = 'Treasuries';

    },

    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.afterSave();
            this.getFetch();
          })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.idEditing}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.afterSave();
            this.getFetch();
          })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    editItem(item) {
      this.idEditing = item.id;
      ApiService.get(this.mainRoute + "/" + item.id).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.code = response.data.data.code;
        this.data.amount = response.data.data.amount;
        this.data.currency_id = response.data.data.currency_id;
        // this.data.tax_id = response.data.data.tax_id;
        // this.data.tax_val = response.data.data.tax_val;
        this.data.expenses_date = response.data.data.expenses_date;
        this.data.recurring_date = response.data.data.recurring_date;
        // this.data.frecuency = response.data.data.frecuency;
        // this.data.attachment = response.data.data.attachment;
        // this.data.notes = response.data.data.notes;
        this.data.status = response.data.data.status;
        // this.data.is_recurring = response.data.data.is_recurring;
        // this.data.inventory_id = response.data.data.inventory_id;
        // this.data.branch_id = response.data.data.branch_id;
        // this.data.account_id = response.data.data.account_id;
        this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.payment_method = response.data.data.payment_method;
        this.data.type_id = response.data.data.type_id;
        this.data.type_type = response.data.data.type_type;
        this.data.doc_type = response.data.data.doc_type;
        this.data.doc_type_id = response.data.data.doc_type_id;
        this.data.class = response.data.data.class;

        this.treasury = response.data.data.treasury;
        this.bank = response.data.data.bank;

      })
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
        Vue.prototype.$postStatus = true;
      })
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val;
      });
    },
    getTreasuries() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });

    },
    getBanks(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
          this.banks = response.data.data;
        });
      } else {
        this.banks = [];
      }
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/exp_status").then((response) => {
        this.status_list = response.data.data;
      });
    },

    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/17").then((response) => {
        this.data.code = response.data.data.code;
      });
    },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;

      });
    },
    afterSave() {
      this.data.name = this.customerName;// + ' '+ (new Date().toISOString().substr(0, 10));
      this.getTimeZone().then((res)=>{
        this.data.name = this.data.name + ' '+ res;
      });
      this.data.code = null;
      this.data.amount = null;
      this.data.status = 1;
      this.data.payment_method = null;
      this.data.type_id = null;
      this.data.type_type = null;
      this.data.doc_type = "Customer";
      this.data.doc_type_id = this.customerId;
      this.data.class = "1";

      this.idEditing = null;
      this.isEditing = false;
      this.treasury = null;
      this.bank = null;

      this.getCode();
    },

  },

  created() {
    this.getCurrencies();
    // this.data.expenses_date = new Date().toISOString().substr(0, 10);
    // this.data.recurring_date = new Date().toISOString().substr(0, 10);

    this.data.doc_type_id = this.customerId;
    this.data.name = this.customerName;// + ' '+ (new Date().toISOString().substr(0, 10));
    this.getTimeZone().then((res)=>{
      this.data.expenses_date = res;
      this.data.recurring_date = res;
      this.data.name = this.data.name + ' '+ res;
    });


    this.getStatusList();
    this.getTreasuries();
    this.getCode();
    this.getPaymentMethods();
    this.defaultDataForUser();


  },
}
</script>
<style scoped>
</style>