<template>
  <div class="form-group row mt-8">
    <div class="col-lg-6 mb-5">
      <label>{{ $t('customers.branch') }}<span class="text-danger">*</span></label>
      <input type="text" class="form-control" :class="  validation && validation.branch_name ? 'is-invalid' : ''" v-model="data.branch_name"/>
      <span v-if="validation && validation.branch_name" class="fv-plugins-message-container invalid-feedback">
                {{ validation.branch_name[0] }}
            </span>
    </div>
    <div class="col-lg-6 mb-5">
      <label>{{ $t('customers.name_on_invoice') }}<span class="text-danger">*</span></label>
      <input type="text" class="form-control" :class="  validation && validation.invoice_name ? 'is-invalid' : ''" v-model="data.invoice_name"/>
      <span v-if="validation && validation.invoice_name" class="fv-plugins-message-container invalid-feedback">
                {{ validation.invoice_name[0] }}
            </span>
    </div>
    <div class="col-lg-6 mb-5">
      <label>{{ $t('customers.country') }}</label>
      <div class="input-group mb-3">
        <multiselect v-model="country"
                     :placeholder="$t('customers.country')"
                     label="name"
                     track-by="id"
                     :options="countries"
                     :multiple="false"
                     :taggable="false"
                     :show-labels="false"
                     :show-no-options="false"
                     :show-no-results="false"
                     @search-change="getCountries($event)"
                     :internal-search="false">
        </multiselect>
      </div>
      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('customers.country') }}.</span>
      <span v-if="validation && validation.country_code" class="fv-plugins-message-container invalid-feedback">
                {{ validation.country_code[0] }}
            </span>
    </div>
    <div class="col-lg-6 mb-5">
      <label>{{ $t('customers.city') }}</label>
      <div class="input-group mb-3">
        <multiselect v-model="city"
                     :placeholder="$t('customers.city')"
                     label="name" track-by="id"
                     :options="cities"
                     :multiple="false"
                     :taggable="true"
                     :show-labels="false"
                     :show-no-options="false"
                     :show-no-results="false">
        </multiselect>
        <div class="input-group-prepend">
          <a class="btn btn-primary" :href="data.country_code?'/settings/cities/'+data.country_code:'#'" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
        </div>
      </div>
      <span v-if="validation && validation.city_id" class="fv-plugins-message-container invalid-feedback">
                {{ validation.city_id[0] }}
            </span>
    </div>
    <div class="col-lg-6 mb-5">
      <label>{{ $t('customers.mobile') }}</label>
      <vue-tel-input v-model="data.mobile" :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
      <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">
                {{ validation.mobile[0] }}
            </span>
    </div>
    <div class="col-lg-6 mb-5">
      <label>{{ $t('customers.address_1') }}</label>
      <input type="text" class="form-control" :class="  validation && validation.address1 ? 'is-invalid' : ''" v-model="data.address1"/>
      <span v-if="validation && validation.address1" class="fv-plugins-message-container invalid-feedback">
                {{ validation.address1[0] }}
            </span>
    </div>


    <div class="col-lg-6 mb-5">
      <label>{{ $t('customers.is_active') }}</label>
      <div class="input-group">
        <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch></b-form-checkbox>
      </div>
    </div>
    <div class="col-lg-12 mb-8 text-center">
      <button type="button" class="btn btn-primary m-2" @click="save">{{ $t('save') }}</button>
    </div>
    <div class="col-lg-12 mb-5 mt-15">
      <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="tableAddress">

        <template slot="mobile" slot-scope="props">
          <span class="tel_number text-center">{{props.row.mobile}}</span>
        </template>

        <template slot="status" slot-scope="props">
          <b-form-checkbox size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
        </template>
        <template slot="actions" slot-scope="props">
          <v-icon small class="text-info mr-2" @click="editItem(props.row)">mdi-pencil</v-icon>
          <v-icon small class="text-danger" @click="deleteItem(props.row)">mdi-delete</v-icon>
        </template>
      </v-server-table>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import Vue from "vue";

export default {
  name: "customer-branches",
  props: ['customerId'],
  data() {
    return {
      mainRoute: 'customers/customer_branches',
      subMainRoute: 'customers/customer_branch',
      mainRouteDependency: 'base/dependency',
      data: {

        customer_id : null,
        city_id: null,
        country_code: null,
        mobile : '',
        address1: null,
        branch_name : null,
        invoice_name : null,
        is_active: true
      },
      data_list: [],
      idEditing: this.$route.params.id,
      isEditing: false,
      countries: [],
      cities: [],
      validation: null,
      country: [],
      city: [],
      default_data: {
        country_code: null,
        city_id: null,
        country: null,
        city: null,
      },

      columns: ['country_name', 'city_name', 'invoice_name', 'branch_name', 'mobile', 'status', 'actions'],
    };
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          country_name: that.$t('customers.country'),
          city_name: that.$t('customers.city'),
          invoice_name: that.$t('customers.name_on_invoice'),
          branch_name: that.$t('customers.branch'),
          mobile: that.$t('customers.mobile'),
          status: that.$t('status'),
          actions: that.$t('actions'),

        },

        filterByColumn: false,
        customFilters: [],
        filterable: false,
        orderBy: {'column': 'id'},
        sortable: [],
        alwaysShowPerPageSelect: false,
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            // filter: data.query,
            customer_id: that.customerId,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },

    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },

  watch: {

    country: function (val) {
      if (val && val.code2) {
        this.data.country_code = val.code2;
        this.getCities(val.code2);
      } else {
        this.data.country_code = null;
        this.cities = [];
      }
    },
    city: function (val) {
      if (val) {
        this.data.city_id = val.id;
      } else {
        this.data.city_id = null;
      }
    },
  },
  methods: {
    mobileRepeaterChanged(obj) {
      if (obj) {
        this.data.mobile = obj.dialCode;
      } else {
        this.data.mobile = '';
      }
    },
    getFetch() {
      this.$refs.tableAddress.refresh();
    },
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      // this.data.is_active = this.data.is_active ? 1 : 0;
      // this.data.is_default = this.data.is_default ? 1 : 0;
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.afterSave();
            this.getFetch();
          })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      // this.data.is_active = this.data.is_active ? 1 : 0;
      // this.data.is_default = this.data.is_default ? 1 : 0;
      ApiService.put(`${this.mainRoute}/${this.data.id}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.afterSave();
            this.getFetch();
          })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    editItem(item) {
      this.idEditing = item.id;
      ApiService.get(this.mainRoute + "/" + item.id).then((response) => {
        this.isEditing = true;
        this.data.id = response.data.data.id;
        this.data.customer_id = response.data.data.customer_id;
        this.data.city_id = response.data.data.city_id;
        this.data.country_code = response.data.data.country_code;
        this.data.branch_name = response.data.data.branch_name;
        this.data.invoice_name = response.data.data.invoice_name;
        this.data.mobile = response.data.data.mobile;
        this.data.address1 = response.data.data.address1;
        this.data.is_active = response.data.data.is_active;

        this.country = response.data.data.country ? response.data.data.country : [];
        this.city = response.data.data.city ? response.data.data.city : [];
      }).catch((error) => {
        this.$errorAlert(error)
      })
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error)
      })
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get("base/dependency/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      } else {
        this.countries = [];
      }
    },
    getCities(id) {
      ApiService.get(`base/dependency/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
    loadOptions() {
    },
    afterSave() {
      this.data = {
        customer_id: this.customerId,
        country_code: this.default_data.country_code,
        city_id: this.default_data.city_id,
        branch_name: null,
        invoice_name: null,
        mobile: '',
        address1: null,
        is_active: true,
        id: null,
      };
      this.country = this.default_data.country;
      this.city = this.default_data.city;

      this.idEditing = null;
      this.isEditing = false;

    },
    /**
     * List actions
     */
    changeStatus(id, status) {
      ApiService.patch(this.subMainRoute + '/change-status/' + id, {
        is_active: (status ? 1 : 0),
      }).then(response => {
        this.getFetch();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },

    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {

        this.data.country_code = response.data.data.country_id;
        this.data.city_id = response.data.data.city_id;
        this.country = response.data.data.country;
        this.city = response.data.data.city;

        this.default_data.country_code = response.data.data.country_id;
        this.default_data.city_id = response.data.data.city_id;
        this.default_data.country = response.data.data.country;
        this.default_data.city = response.data.data.city;



      });
    },
  },

  mounted() {
    this.defaultDataForUser();
    // this.getCountries();
    this.data.customer_id = this.customerId;
  },
}
</script>

<style scoped>

</style>